
import React from 'react'
import Axios from 'axios';

var host      = "https://api.cosmetic-safety.eu/index.php/wp-json/substances/v1/";
var headers   = { 'Authorization': 'Bearer my-token' };

export default {         
                
    get: async (endpoint, params) => { 
                
        const res = Axios.get(host + endpoint, {params:params, headers:{'Authorization': 'Bearer onomatope'}}); 
        
        return res;
    }

};