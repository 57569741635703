import { useState, useEffect}   from "react";
import axios                    from 'axios';
import SearchResults            from '../SearchResults/SearchResults'
import Corrections              from '../Corrections/Corrections'
import Loader                   from '../Loader/Loader'
import Api                      from '../includes/Api';
import Dictionary               from '../includes/Dictionary';

export default () => {
  
    const [search,                      setSearch]                      = useState("");//POLYURETHANE-19
    const [is_searching,                setIsSearching]                 = useState(false);
    const [search_results,              setSearchResults]               = useState([]);
    const [search_corrections,          setSearchCorrections]           = useState([]);
    const [selected_substance,          setSelectedSubstance]           = useState(null);
    const [selected_substance_details,  setSelectedSubstanceDetails]    = useState(null);
    const [reset_results,               setResetResults]                = useState(false);
    const [error,                       setError]                       = useState(null); 
    const [lang,                        setLang]                        = useState("fr");
    
    
    useEffect(() => {
        
        const params  = new URLSearchParams(window.location.search);       
                      
        setLang(localStorage.getItem("lang") || "fr");
        
        const get_search    = params.get("search");              
        
        if(get_search){
            
            setSearch(get_search);
            submitSearch(get_search);
        }
        
    }, []);
    
    const submitSearch = async (search_pattern) => {
        
        setSearchResults([]);
        setIsSearching(true);
        setResetResults(true);
        setSelectedSubstance(null);
        setSelectedSubstanceDetails(null);        
        setError(null);
        
        let results  = await Api.get("substances/search", {search:search_pattern.toUpperCase(), _limit:0});  
        
        setIsSearching(false); 
        
        if(results.data.error === false){
            
            setSearchResults(results.data.content.search);   
            setSearchCorrections(results.data.content.correct);
            setResetResults(false);            
        }
        else{

            setError(results.data.content);          
        }        
    };
    
    const drawError = (error) => {
     
        const errors = {search_too_short: Dictionary.get(lang, "search_too_short")}
        
        return errors[error] ? errors[error] : Dictionary.get(lang, "unexpected_error")
    };
    
    const keyPress = (e) => {
        
        if(e.charCode === 13){
            
            submitSearch(search);
        }
    };
    
    const onSelectSubstance = async (id) => {
        
        setSelectedSubstanceDetails(null);
        setSelectedSubstance(id);
        
        let details = await Api.get("substance/"+id+"/details"); 
        
        if(details.data.error === false){            
            
            setSelectedSubstanceDetails(details.data.content);
        }
    };
    
    const reRunSearch = (new_search) => {
        
        setSearch(new_search);
        
        submitSearch(new_search);
    }
    
    return (
        <>    
            <input 
                id          = "search-box" 
                value       = {search} 
                onChange    = {(e) => setSearch(e.target.value)}
                onKeyPress  = {keyPress}/>
            
            { error ? <div id="error">{drawError(error)}</div> : "" }                       
            {
                is_searching ?  <p><Loader /></p> 
                             :  <>
                                <p>
                                    <button onClick={(e) => submitSearch(search)}>{Dictionary.get(lang, "search")}</button>                 
                                </p>
                               
                                <br/><br/>
                                
                                <Corrections  lang={lang} search={search} reRunSearch={reRunSearch} corrections = {search_corrections} />
                                <SearchResults 
                                    results             = {search_results}                                   
                                    reset               = {reset_results} 
                                    selected_substance  = {selected_substance}
                                    details             = {selected_substance_details}
                                    onSelectSubstance   = {onSelectSubstance}/>                               
                                </>                                
            }            
           
        </>
    );
}

