
import React from 'react'
import { useState, useEffect}   from "react";

var lang = "fr";

export default  {         

    
    get: (lang, value) => {

         
        const data =  {
        
            search:{
           
                en:"Search",
                fr:"Rechercher"
            },
                           
            
            similar_result:{

                en:"Similars results",
                fr:"Résultats similaires"
            },
            
            similars_results:{

                en:"Similars results",
                fr:"Résultats similaires"
            },
            
            search_too_short:{

                en:"The search must have at less 3 characters.",
                fr:"La recherche doit être composée d'au moins 3 caractères"
            },
            
            display:{

                en:"Display",
                fr:"Afficher"
            },
            
            search_label:{
                
                en:"CAS/EINECS and synonym",
                fr:"CAS/EINECS et nom synonyme"
            },
            
            search_label_advanced:{
                
                 en:"Substances and regulations",
                fr:"Substances et règlementation",               
            },
            
            unexpected_error:{
                
                en:"An unexpected error happened, please try again.",
                fr:"Une erreur inattendue s'est produite, merci de rééssayer"
            }
        };
       
        return data[value][lang];
    }
    
}