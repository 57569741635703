import { useState, useEffect}   from "react";
import AnnexDetails             from "./AnnexDetails";
import CosingDetails            from "./CosingDetails";
import IfraDetails              from "./IfraDetails";
import Loader                   from "../Loader/Loader";
import "./style.css";

export default (props) => {
        
    const [show_result, setShowResult] = useState(null);

    useEffect(() => {            
   
        if(props.reset){
            
            setShowResult(null);
        }
    });
    
    const onSelectSubstance = (e, id) => {
        
        e.preventDefault();
        
        props.onSelectSubstance(id);        
    };
    
    const drawResults = (results) => {
        
        let res = [];
            
        Object.entries(results).forEach( (substance) => {
            
            res.push(drawLine(substance[1]));                   
        });
        
        return  res.length > 0 ? 
                    <table id='table-results-search'>
                        <tr>                           
                            <th>Substance</th>
                            <th>Glossary</th>
                            <th>Cosing</th>
                            <th>Other</th>
                            <th>Annexes</th>
                            <th>IFRA</th>
                            
                        </tr>
                        <tbody>{res}</tbody>
                    </table>
                    : "";
    };      
    
    const getAnnexesStickers = (substance) => {
    
        const annexes   = ["ii", "iii", "iv", "v", "vi"];
        const res       = [];
        
        for(const annexe of annexes){
            
            if(substance["ref_annex_"+annexe]){
                
                res.push(<span style={{marginRight:"10px"}}>{annexe.toUpperCase()}/{substance["ref_annex_"+annexe]}</span>);
            }
        }
        
        return res;
    };
    
    const drawLine = (substance) => {
             
        let res             = []; 
        let details         = [];
        let is_selected     = substance.id === props.selected_substance;
        let substance_name  = "";        
        
        /*
        if(substance.glossary?.common_ingredient_name){
            
            substance_name = substance.glossary?.common_ingredient_name
        }
        else if(substance.cosing?.inci_name){
            
            substance_name = substance.cosing?.inci_name;   
        }
        else if(substance.added_substance?.name){
            
            substance_name = substance.added_substance?.name;   
        }*/
            
            substance_name = substance.name;
          
        let annexes_stickers = "";
        
        if(getAnnexesStickers(substance).length > 0){
            
            annexes_stickers = <div>{getAnnexesStickers(substance)}</div>
        }
        
        res.push(<tr>                  
                    <td class={is_selected ? "selected-substance" : ""}>
                        <a 
                            href    = "#" 
                            onClick = {(e) => onSelectSubstance(e, substance.id)}>
                            {substance_name}                          
                        </a>
                    </td> 
                    <td class={is_selected ? "selected-substance" : ""}>{substance.glossary ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance.cosing ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance.added_substance ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{annexes_stickers}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance?.ifra_standard ? "IFRA_STD_"+substance.ifra_standard : ""}</td>
                   
                </tr>);
        
        if(is_selected && !props.details){
            
            details.push(<Loader />);
        }
        else if(is_selected && props.details){
                                
            // Cosing
            if(props.details.cosing !== null){
                
                details.push(<CosingDetails data={props.details.cosing} />);
            }
            
            // Annexes

            Object.entries(props.details.annexes).forEach(annexe => {

                let number  = annexe[0];
                let data    = annexe[1];                

                if(data.length > 0){

                    details.push(<AnnexDetails number={number} data={data} />);                                         
                }                                
            });  
           
            
            // Ifras
           
            if(props.details?.ifra != null){
                          
                details.push(<IfraDetails data={props.details.ifra} />);    
            }
           
        }
               
        if(details.length > 0){
             
            res.push(

                <tr>                  
                    <td colspan="7">
                       {details}
                    </td>
                </tr>
            );
        }
            
        return res;
    };
    
    return (
        <>
        { drawResults(props.results)}
        </> 
    );
}

