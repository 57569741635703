
import "./style.css";

export default (props) => {
    
    const draw = () => {
        
        let list = [];
        let res  = [];               

        if(props.data.length > 0){
               
            // Group entries by reference number
            let groups = {};
            
            for(const entry of props.data){
              
                if( !groups.hasOwnProperty(entry.reference_number) ){
                    
                    groups[entry.reference_number] = [];
                }
                
                groups[entry.reference_number].push(entry);      
            }
            
            // Convert each group entries to lines (table lines)
            let lines = {};
            
            for(const reference_number in groups){
                
                const entries           = groups[reference_number];
                lines[reference_number] = [];   
                
                entries.forEach( entry => {
                    
                    const line = getLineEntry(entry);
                    
                    if(line){
                        
                        lines[reference_number].push(line);                    
                    }
                });
            }
                                
            for(const reference_number in lines){
            
                const line_entries = lines[reference_number];                
                const draw_lines   = props.number == "ii" ? drawLinesAnnexII(line_entries)
                                                          : drawLines(line_entries);
                
                if(draw_lines){
                                                            
                    res.push(<section class="annex">
                            <div><b>Annex {props.number.toUpperCase()} - Reference {reference_number}</b></div>
                            {
                              draw_lines
                            }
                          </section>);
                }
            }                                
        }
        
        return res;      
    };
    
    const getLineEntry = (entry) => {
        
        //const reference_entries = props.data.body_parts_max_concentration[reference_number] || null;
      
        let res = null;
        
        if(props.number == "ii"){
                                
                res = {
                    
                    chemical_name_inn:  entry.chemical_name_inn,
                    cmr:                entry.cmr,
                    update_date:        entry.date_update
                };
        } 
        else{
                /*let max_concentration   = null;
                let body_parts          = null;
                
                if( entry.all_product_types_body_parts ){
                    
                    body_parts = entry.all_product_types_body_parts;
                }
                else{
                    
                    if(entry.product_types_body_parts){
                        
                        body_parts = entry.product_types_body_parts;
                    }
                    else{
                        
                        // Loop?
                        body_parts = entry.product_types_body_parts_parsed;
                    }
                }
                                               
                if(body_parts){
                    
                    body_parts = body_parts + "/" + entry.max_concentration;
                }
                */
               
                let max_concentration   = entry.max_concentration_use_preparation || entry.max_concentration_in_ready_for_use || null;                                            
                let wordings            = entry.wording_conditions_use_warnings || entry.wording_of_conditions_of_use_and_warning || null;
                let update_date         = entry.date_update || entry.update_date || null;
                
                res = {

                    body_parts:         entry.product_type_body_parts, 
                    max_concentration:  max_concentration,
                    other:              entry.other, 
                    wordings:           wordings,
                    update_date:        update_date
                };                  
           }
        
        return res;
    };

    const addLineTimestamp = (line) => {
        
        const update_date    = line.update_date?.split("/").reverse().join("/") || "";
        const date           = new Date(update_date);                   
        line.timestamp       = date.getTime();
        
        return line;
    };

    const sortLinesByTimestamp = (lines) => {
        
        lines.sort(function(a, b) { 
            
            return b.timestamp - a.timestamp;
        });
    };
    
    const drawLinesAnnexII = (lines) => {
        
                     
        let res                     = [];
        let lines_with_timestamps   = [];   

        lines.forEach( line => {

            if(line.chemical_name_inn || line.cmr || line.other ||  line.upate_date){
                                
                line = addLineTimestamp(line);

                lines_with_timestamps.push(line);
            }
        });
        
        sortLinesByTimestamp(lines_with_timestamps);
        
        lines_with_timestamps.forEach( line => {
            
            res.push(
                    <tr>
                        <td>{line.update_date}</td>
                        <td>{line.chemical_name_inn}</td>
                        <td>{line.cmr}</td>                                           
                    </tr>); 
        });
          
        return  res.length > 0 ? 
                    <table class="table-details">
                    <tr>
                        <th>Update date</th>
                        <th>Chemical name inn</th>                        
                        <th>CMR</th>                                      
                    </tr>
                    { res }
                    </table>
                : null;
    };
    
    
    const drawLines = (lines) => {
                    
        let res                     = [];
        let lines_with_timestamps   = [];   
                
        lines.forEach( line => {

            if(line.body_parts || line.max_concentration || line.other ||  line.wordings || line.update_date){

                                     
                const update_date    = line.update_date?.split("/").reverse().join("/") || "";
                const date           = new Date(update_date);                   
                line.timestamp       = date.getTime();

                lines_with_timestamps.push(line);
            }
        });
        
        lines_with_timestamps.sort(function(a, b) { 
            
            return b.timestamp - a.timestamp;
        });
        
        lines_with_timestamps.forEach( line => {
                    
            res.push(
                    <tr>
                        <td>{line.update_date}</td>
                        <td>{line.body_parts}</td>
                        <td>{line.max_concentration}</td>
                        <td>{line.other}</td>
                        <td>{line.wordings}</td>
                        
                    </tr>); 
        });
          
        return  res.length > 0 ? 
                    <table class="table-details">
                    <tr>
                        <th>Update date</th>
                        <th>Product types or body parts</th>                        
                        <th>Max concentration in ready for use preparation</th>
                        <th>Other condition</th>
                        <th>Wording of conditions of use and warnings</th>                        
                    </tr>
                    { res }
                    </table>
                : null;
    };
    
    
    
    
    
    
    return draw();
}

