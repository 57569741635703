import { useState, useEffect}   from "react";
import "./style.css";
import IfraDetails from "./IfraDetails";

export default (props) => {

    const [show_details, setShowDetails] = useState(null);
      
    const select = (e, id) => {
        
        e.preventDefault();
        
        if(show_details === id){
            
            setShowDetails(null);
        }
        else{
            setShowDetails(id);
        }
    }
    
    return (
        <div style={{maxWidth:"90%"}}>
        <table id="table-results">
        <tr>
            <th>IFRA Standard</th>
            <th>Name of the IFRA standard</th>
            <th>CAS</th>
            <th>Synonyms</th>            
        </tr>
        
        {
            props.entries.map((entry) => 
                <>
                <tr>
                    <td>{entry.key}</td>
                    <td>{entry.name_of_the_ifra_standard}</td>
                    <td>{entry.cas}</td> 
                    <td>{entry.synonyms}</td>                    
                </tr>
                </>
            )
        }        
        </table> 
        </div>
    );
}

