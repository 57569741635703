
import "./style.css";

export default (props) => {
        
  
    return (
        <>
            <section>
            <div><b>Cosing</b></div>
            {
                props.data.cas_no ? 
                    <div class="details">
                        <div class="details-title">CAS:</div>
                        <div class="details-content">{props.data.cas_no}</div>
                    </div> : ""
            }
            {
                props.data.ec_no ? 
                    <div class="details">
                        <div class="details-title">EINECS:</div>
                        <div class="details-content">{props.data.ec_no}</div>
                    </div> : ""
            }
            {
                props.data.function ? 
                    <div class="details">
                        <div class="details-title">Function:</div>
                        <div class="details-content">{props.data.function}</div>
                    </div> : ""
            }
            {
                props.data.chem_iupac_name___description ? 
                    <div class="details">
                        <div class="details-title">Description:</div>
                        <div class="details-content">{props.data.chem_iupac_name___description}</div>
                    </div> : ""
            }               
            </section>
           
        </> 
    );
}

