import "./style.css";
import { useState, useEffect}   from "react";
import Dictionary from "../includes/Dictionary"
import Lang from "../Lang/Lang"

export default (props) => {
        
    const [lang, setLang] = useState("fr");    
    
    useEffect(() => {                  
                      
        setLang(localStorage.getItem("lang") || "fr");
              
    }, []);    
    
    const [show, setShow] = useState(false);
              
    return (
        <>    
            <div id="menu">
                <Lang />

                <div>
                { 
                    window.location.pathname === "/" ? 
                        <span>{Dictionary.get(lang, "search_label_advanced")}</span>
                    :
                        <a href="/">{Dictionary.get(lang, "search_label_advanced")}</a>
                }                
                </div>

                <div>
                { 
                    window.location.pathname === "/advanced-search" ? 
                        <span>{Dictionary.get(lang, "search_label")}</span>
                    :
                        <a href="/advanced-search">{Dictionary.get(lang, "search_label")}</a>
                }                
                </div>
                
            </div>
        </> 
    );
}

