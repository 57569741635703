import { useState, useEffect}   from "react";

import CosingResults    from "./CosingResults";
import IfraResults      from "./IfraResults";
import AnnexResults     from "./AnnexResults";
import AnnexiiResults   from "./AnnexiiResults";
import Loader           from "../../Loader/Loader"; 
import "./style.css";

export default (props) => {
        
    const [show_result, setShowResult] = useState(null);

    useEffect(() => {            
   
        if(props.reset){
            
            setShowResult(null);
        }
    });
    
    const onSelectSubstance = (e, id) => {
        
        e.preventDefault();
        
        props.onSelectSubstance(id);        
    };
    
    const drawResults = (results) => {
        
        let res = [];

        if(results.cosing && results.cosing.length > 0){
                       
            res.push(<div class="section-title"><h1>COSING</h1></div>)            
            res.push(<CosingResults entries={results.cosing}/>)
        }
        
        if(results.ifra && results.ifra.length > 0){
                       
            res.push(<div class="section-title"><h1>IFRA</h1></div>)
            res.push(<IfraResults entries={results.ifra}/>)
        }
        
        if(results.annex_ii && results.annex_ii.length > 0){
            
            res.push(<div class="section-title"><h1>Annex II</h1></div>) 
            res.push(<AnnexiiResults entries={results.annex_ii}/>)
        }
        
        if(results.annex_iii && results.annex_iii.length > 0){
            
            res.push(<div class="section-title"><h1>Annex III</h1></div>) 
            res.push(<AnnexResults entries={results.annex_iii}/>)
        }
        
        if(results.annex_iv && results.annex_iv.length > 0){
            
            res.push(<div class="section-title"><h1>Annex IV</h1></div>)  
            res.push(<AnnexResults entries={results.annex_iv}/>)
        }
        
        if(results.annex_v && results.annex_v.length > 0){
            
            res.push(<div class="section-title"><h1>Annex V</h1></div>) 
            res.push(<AnnexResults entries={results.annex_v}/>)
        }
        
        if(results.annex_vi && results.annex_vi.length > 0){
            
            res.push(<div class="section-title"><h1>Annex VI</h1></div>)  
            res.push(<AnnexResults entries={results.annex_vi}/>)
        }
        
        return res;
        /*
        Object.entries(results).forEach( (substance) => {
            
            res.push(drawLine(substance[1]));                   
        });
        
        return  res.length > 0 ? 
                    <table id='table-results'>
                        <tr>                           
                            <th>Substance</th>
                            <th>Glossary</th>
                            <th>Cosing</th>
                            <th>Other</th>
                            <th>Annexes</th>
                            <th>IFRA</th>
                            
                        </tr>
                        <tbody>{res}</tbody>
                    </table>
                    : "";
             * 
         */
    };      
    
    const getAnnexesStickers = (substance) => {
    
        const annexes   = ["ii", "iii", "iv", "v", "vi"];
        const res       = [];
        
        for(const annexe of annexes){
            
            if(substance["ref_annex_"+annexe]){
                
                res.push(<span style={{marginRight:"10px"}}>{annexe.toUpperCase()}/{substance["ref_annex_"+annexe]}</span>);
            }
        }
        
        return res;
    };
    
    const drawLine = (substance) => {
             
        let res             = []; 
        let details         = [];
        let is_selected     = substance.id === props.selected_substance;
        let substance_name  = "";        
        
        substance_name = substance.name;
          
        let annexes_stickers = "";
        
        if(getAnnexesStickers(substance).length > 0){
            
            annexes_stickers = <div>{getAnnexesStickers(substance)}</div>
        }
        
        res.push(<tr>                  
                    <td class={is_selected ? "selected-substance" : ""}>
                        <a 
                            href    = "#" 
                            onClick = {(e) => onSelectSubstance(e, substance.id)}>
                            {substance_name}                          
                        </a>
                    </td> 
                    <td class={is_selected ? "selected-substance" : ""}>{substance.glossary ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance.cosing ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance.added_substance ? "X" : ""}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{annexes_stickers}</td>
                    <td class={is_selected ? "selected-substance" : ""}>{substance?.ifra_standard}</td>
                   
                </tr>);
        
        if(is_selected && !props.details){
            
            details.push(<Loader />);
        }
        else if(is_selected && props.details){
                                
           
        }
               
        if(details.length > 0){
             
            res.push(

                <tr>                  
                    <td colspan="7">
                       {details}
                    </td>
                </tr>
            );
        }
            
        return res;
    };
    
    return (
        <>
        {drawResults(props.results)}
        </> 
    );
}

