import './App.css';
import FormPanel from './FormPanel/FormPanel';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Advanced from './Advanced';
import Lang from './Lang/Lang';
import Menu from './Menu/Menu';

function App() {
        
    const params = new URLSearchParams(window.location.search);

    if(params.get("lang")){

        localStorage.setItem("lang", params.get("lang"));
    }
        
    return (
      <>
      <Menu />     
      <BrowserRouter>    
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/advanced-search" element={<Advanced />} />
          </Routes>
      </BrowserRouter>
     </>
    );
}

export default App;