import "./style.css";
import { useState, useEffect}   from "react";

export default (props) => {
        
    const [lang, setLang] = useState(localStorage.getItem("lang") || "fr");        
         
    const setLangAsFR = (e) => {
        
        localStorage.setItem("lang", "fr");
        
    };
    
    const setLangAsEN = (e) => {
        
        localStorage.setItem("lang", "en");
    };
    
    return (
        <>  
            <div id="lang-selector">
            <span classd="lang">{ lang === "fr" ? <span>FR</span> : <a href='' onClick={setLangAsFR}>FR</a> }</span>
            <span class="lang">{ lang === "en" ? <span>EN</span> : <a href='' onClick={setLangAsEN}>EN</a> }</span>
            </div>
        </> 
    );
}