
import "./style.css";

export default (props) => {
    
    const draw = (side) => {
                
        let res  = [];               
        
        if(props.data){
            
            const levels_of_use = drawLevelOfUse(side);
            
            if(levels_of_use.length > 0){
        
                res.push(

                    <table class="table-details">
                       <tr>    
                           <th>IFRA categories</th>
                           <th>Level of use (%)</th>
                       </tr>
                      {levels_of_use}                       
                   </table>               
                ); 
            }
        }
        
        return res;      
    };

    const drawLevelOfUse = (side) => {
        
        const list_cat = side === "left" ? ["1","2","3","4","5a","5b","5c","5d","6"]
                                         : ["7a","7b","8","9","10a","10b", "11a", "11b", "12"];
        let res         = [];
        
        list_cat.forEach( cat => {
            
            const level_of_use = props.data["category_"+cat];
            
            if(level_of_use){
                
                res.push(
                    <tr>
                        <td>Category {cat.toUpperCase()}</td>
                        <td>{level_of_use}</td>                           
                    </tr>);
            }
        });
                
        return  res;
                    
    };
    
    const drawInfos = () => {
    
        let res = [];
        
        const infos_list = [
            
            "ifra_standard_type",    
            "intrinsic_property_driving_the_risk_management_measure",
            "prohibited_fragrance_ingredients_notes",    
            "restricted_ingredients_due_to_phototoxicity_considerations_note",
            "restricted_ingredients_notes",    
            "specified_ingredients_notes"
        ];
        
        for(const info of infos_list){
            
            const title     = info.split("_").join(" ");
            const content   = props.data[info];
            
            if(content){
                res.push(

                    <div class="details">
                        <div class="details-title">{title}</div>
                        <div class="details-content">{content}</div>
                    </div>
                );
            }
        }
        
        return res;
    }    
    
    //convert IFRA_STD_026 en 26    
    const getKeyNumber = (key) => {
    
        const number = key?.split("_")[2];
        
        return parseInt(number);
    };
    
    return props.data ? 
            <section class="annex">                       
                <div><b>{props.data.key}</b></div>
                
                {drawInfos()}
                
                <div id="ifra-categories-tables">
                    <div>{draw("left")}</div>
                    <div>{draw("right")}</div>
                </div>
            </section>
            : "";
}

