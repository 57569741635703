import './App.css';
import FormPanel from './Advanced/FormPanel/FormPanel';


function App() {
    
    return (
        <div className="App">
            <header className="App-header">
              <FormPanel />
            </header>
        </div>
    );
}

export default App;