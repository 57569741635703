
import { useState, useEffect}   from "react";
import "./style.css";
import AnnexiiDetails from "./AnnexiiDetails";

export default (props) => {

    const [show_details, setShowDetails] = useState(null);
    
    const select = (e, id) => {
        
        e.preventDefault();
        
        if(show_details === id){
            
            setShowDetails(null);
        }
        else{
            setShowDetails(id);
        }
    }
    
    const convertToLink = (substances) => {
        
        const split = substances.split("\n");
        let res = [];
        
        split.forEach((value) => {
            
            if(value.length !== ""){
                
                    res.push(<p><a href={"/?search="+value}>{value}</a></p>);
            }
        })
        
        return res;
    }
    
    return (
        <div style={{maxWidth:"90%"}}>
        <table id="table-results">
        <tr> 
            <th>Reference number</th>
            <th>Chemical name INN</th>
            <th>Chemical IUPAC name</th>
            <th>CAS</th>
            <th>EC</th>  
            <th>Identified ingredients or substances</th>
        </tr>
         
        {
            props.entries.map((entry) => 
                <>                

                <tr>
                    <td>{entry.reference_number}</td>
                    <td>{entry.chemical_name_inn}</td>
                    <td>{entry.chemical_iupac_name}</td>
                    <td>{entry.cas}</td>
                    <td>{entry.ec}</td>  
                    <td>{convertToLink(entry.identified_ingredients_or_substances)}</td>
                </tr>
                </>
            )
        }        
        </table> 
        </div>
    );
}
