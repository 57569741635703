import React from 'react';
import './style.css'

export default (props) => {
        
    return (
        
        props.centered === "true" ? <div class="centered-loader"><div class="lds-dual-ring"></div></div>
                                  : <div class="lds-dual-ring"></div>
    );
}

