import { useState, useEffect}   from "react";
import "./style.css";
import CosingDetails from "./CosingDetails";

export default (props) => {

    const [show_details, setShowDetails] = useState(null);
    
    const select = (e, id) => {
        
        e.preventDefault();
        
        if(show_details === id){
            
            setShowDetails(null);
        }
        else{
            setShowDetails(id);
        }
    }
    
    return (
        <div style={{maxWidth:"90%"}}>
        <table id="table-results">
        <tr>
            <th>INCI name</th>           
            <th>CAS</th>
            <th>EINECS</th>            
            <th>Description</th>
        </tr>        
        {
            props.entries.map((entry) => 
                <>                
                {                   
                    <tr>
                    <td><a href={"/?search="+entry.inci_name}>{entry.inci_name}</a></td>                                   
                       <td>{entry.cas_no}</td>
                       <td>{entry.ec_no}</td>                       
                       <td>{entry.chem_iupac_name___description}</td>            
                   </tr>                                                
                }
                </>
            )
        }        
        </table> 
        </div>
    );
}

