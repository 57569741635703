import "./style.css";
import { useState, useEffect}   from "react";
import Dictionary from "../includes/Dictionary"

export default (props) => {
    
    const [show, setShow] = useState(false);
    
    const reRunSearch = (e, key) => {
        
        e.preventDefault();
        
        props.reRunSearch(key);
    };
    
    const drawCorrections = (corrections) => {
        
        const res = [];
        
        for(const correction of corrections){
            
                res.push(<div><a href='' onClick={(e) => reRunSearch(e, correction)}>{correction}</a></div>)
        }
        
        return res;
    };
  
    const drawTitle = (length) => {
        
        let res = <div>{Dictionary.get(props.lang, "similar_result")} (1)</div>;
        
        if(length > 1){
            
            res = <div>{Dictionary.get(props.lang, "similars_results")} {length} </div>
        }
        
        return res;
    };
    
    
    return (
        <>    
            {   
                props.corrections.length > 0 ?
                    <div class="results-container">
                    <p>{drawTitle(props.corrections.length)}</p>
                    { show ? drawCorrections(props.corrections) : <button onClick={(e) => setShow(true)}>{Dictionary.get(props.lang, "display")}</button>}
                    </div>
                    : ""
            }
        </> 
    );
}

